<template>
  <div>
    <ed-convenio :intConvenioId="0" :key="$root.$session.versao" />
  </div>
</template>

<script>
import EdConvenio from "@/components/cadastro/convenio/editar";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdConvenio },
  mounted() {},
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
